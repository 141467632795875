import React from 'react'
import { Link } from 'gatsby'

import "./bannerHome.scss"

import BannerHomeImg from "../../../assets/images/banners/ibx-agencia-marketing-digital.png"

const BannerHome = () => {

    return (
        <div className="space-3-bottom">
            <div className="banner">
                <div className="banner-home__cover-image" />
                <div className="banner__container">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                                <div className="banner__title space-1-bottom">
                                    <h1 className="banner__title-h1">Agencia Marketing Digital en Barcelona</h1>
                                </div>
                                <div className="banner__subtitle space-1-bottom">
                                    <p>Expertos en páginas web y tiendas online, posicionamiento web SEO, publicidad online (SEM- Google Ads &amp; Social Media Ads), redes sociales, content marketing, branding, diseño gráfico, publicitario y editorial, impresión y fotografía y vídeo.</p>
                                    <p>Si tu objetivo es dar visibilidad a tu marca,  promocionar tus servicios o vender tus productos en Google... ¡Somos la <strong>Agencia de Marketing Digital en Barcelona</strong> que estás buscando!</p>
                                </div>
                                <div className="banner__actions space-2-bottom--xs">
                                    <Link className="banner__actions-link" to="/contacto" >Contacta ahora</Link>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 col-xl-6">
                                <div className="banner__img">
                                    <img src={BannerHomeImg} alt="Agencia Marketing Digital en Barcelona" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="banner-home__footer">
                <div className="banner__wave" />
            </div>
        </div>
    )
}


export default BannerHome