import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/common/Layout/layout"
import SEO from "../components/common/SEO/seo"
import BannerHome from "../components/banner/BannerHome/bannerHome"
import SectionTextImage from "../components/section/TextImage/textImage"
import SectionMarketingServices from "../components/section/MarketingServices/marketingServices"
import GraphicDesignServices from "../components/section/GraphicDesignServices/graphicDesignServices"
import GraphicProductionServices from "../components/section/GraphicProductionServices/graphicProductionServices"
import AudiovisualProductionServices from "../components/section/AudiovisualProductionServices/audiovisualProductionServices"
import CallToAction from "../components/section/CallToAction/callToAction"

const HomePage = () => {
    const data = useStaticQuery(graphql`
    query {
      marketingDigitalImage: file(relativePath: { eq: "images/sections/plan-marketing-digital.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      graphicDesignImage: file(relativePath: { eq: "images/sections/diseno-grafico.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      graphicProductionImage: file(relativePath: { eq: "images/sections/produccion-grafica.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      photographicStudioImage: file(relativePath: { eq: "images/sections/produccion-audiovisual.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="ibx"
                description="ibx - Agencia de Marketing Digital en Barcelona experta en creación web, tiendas online, posicionamiento SEO, SEM, redes sociales, diseño gráfico y editorial."
            />

            <BannerHome />
            <SectionTextImage
                title1="Marketing"
                title2="Digital"
                titleUrl="/marketing-digital"
                text={<Fragment>¿Necesita tu negocio un Plan de Marketing Digital para dar a conocer tus productos y servicios en Google? En ibx elaboramos aquella estrategia integral personalizada que tu marca precisa para conseguir tus objetivos; <strong>más ventas y contactos</strong>.</Fragment>}
                button="Haz que despegue tu negocio"
                buttonUrl="/marketing-digital"
                image={data.marketingDigitalImage.childImageSharp.fluid}
                imageAlt="Marketing Digital - Agencia Marketing Digital"
                color="green"
                containerTextRight={true}
            />
            <SectionMarketingServices />
            <hr className="space-2-bottom space-3-bottom--lg" />
            <SectionTextImage
                title1="Diseño"
                title2="gráfico"
                titleUrl="/diseno-grafico"
                text={<Fragment>¿Ya tienes todo el material gráfico y publicitario necesario para explicar a tus potenciales clientes que les puedes ofrecer? ¿Qué presencia tiene tu logo? ¿Dispones de una identidad corporativa que te defina? ¡En ibx <strong>damos forma a tu marca</strong>!</Fragment>}
                button="Diseña tus ideas"
                buttonUrl="/diseno-grafico"
                image={data.graphicDesignImage.childImageSharp.fluid}
                imageAlt="Diseño Gráfico - Agencia Marketing Digital"
                color="pink"
                containerTextRight={false}
            />
            <GraphicDesignServices />
            <hr className="space-2-bottom space-3-bottom--lg" />
            <SectionTextImage
                title1="Producción"
                title2="gráfica"
                titleUrl="/produccion-grafica"
                text={<Fragment>¿Quieres plasmar lo que son tus productos y servicios en un soporte físico? Con la impresión de publicidad podrás producir mensajes publicitarios personalizados para captar la atención de tu público objetivo ¡En ibx te <strong>ofrecemos servicios de producción gráfica de alta calidad</strong>!</Fragment>}
                button="Imprime tus proyectos"
                buttonUrl="/produccion-grafica"
                image={data.graphicProductionImage.childImageSharp.fluid}
                imageAlt="Producción Gráfica - Agencia Marketing Digital"
                color="blue"
                containerTextRight={true}
            />
            <GraphicProductionServices />
            <hr className="space-2-bottom space-3-bottom--lg" />
            <SectionTextImage
                title1="Productora"
                title2="Audiovisual"
                titleUrl="/productora-audiovisual"
                text={<Fragment>¡Si una imagen vale más que mil palabras, imagínate el impacto que produce una pieza audiovisual! Cuidar hasta el último detalle de tu marca es importante para vender tus productos o servicios.¡En ibx <strong>creamos piezas audiovisuales impactantes</strong>!</Fragment>}
                button="¡Qué empieze el show!"
                buttonUrl="/productora-audiovisual"
                image={data.photographicStudioImage.childImageSharp.fluid}
                imageAlt="Productora Audiovisual - Agencia Marketing Digital"
                color="yellow"
                containerTextRight={false}
            />
            <AudiovisualProductionServices />

            <CallToAction
                color="green"
                title="¿Necesitas un plan de marketing digital para tu empresa?"
                text={<Fragment>
                    Somos tu ¡<strong>Agencia de Marketing Digital 360</strong> en Barcelona!
                </Fragment>}
                button="Contacta ahora"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default HomePage
